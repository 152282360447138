<template>
  <div eagle-form-item="photo">
    <photo-selector
      :disabled="disabled || readonly"
      :disabledRemove="formDataItem.disabledRemove === true"
      :photoManager="photoManager"
      :onPhotoUpdate="onPhotoUpdate"
      :type="photoType"
      :multiple="multiple"
      :total="total"
      v-model="data"
    ></photo-selector>
  </div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.data = window.eagleLodash.cloneDeep(this.storeData)
    },
    onPhotoUpdate(photo) {
      if(typeof this.formDataItem.onPhotoUpdate != 'function') return
      this.formDataItem.onPhotoUpdate(photo)
    },
  },
  computed: {
    photoType() {
      return this.formDataItem.photoType || 'default'
    },
    multiple() {
      return this.formDataItem.multiple === true
    },
    total() {
      return this.formDataItem.total || null
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.data, this.storeData)) return
        this.sync()
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
